import useIsMobile from "@/composables/useIsMobile";
import { throttle } from "lodash";
import { computed, onMounted, onUnmounted, ref } from "vue";

export default function useScrollingDirection() {
  // changes instantly (with throttling of course)
  const scrollingDirection = ref("down");
  // changes after Offset is scrolled
  const scrollingDirectionMain = ref("down");

  const { isMobile } = useIsMobile();
  const directionMainOffset = computed(() => (isMobile.value ? 300 : 400));

  let scrollPosition = window.pageYOffset;
  let scrollPositionMain = window.pageYOffset;

  const handleScroll = () => {
    scrollPosition = _setScrollingDirection(scrollingDirection, {
      prevScrollPosition: scrollPosition,
    });
    scrollPositionMain = _setScrollingDirection(scrollingDirectionMain, {
      prevScrollPosition: scrollPositionMain,
      offset: directionMainOffset.value,
    });
  };

  const handleScrollThrottle = throttle(handleScroll, 100);

  onMounted(() => {
    window.addEventListener("scroll", handleScrollThrottle);
  });

  onUnmounted(() => {
    window.removeEventListener("scroll", handleScrollThrottle);
  });

  const _setScrollingDirection = (
    directionRef,
    { prevScrollPosition, offset = 0 }
  ) => {
    const prevScrollingDirection = directionRef.value;

    const { newScrollingDirection, newScrollPosition } = _getScrollingDirection(
      {
        prevScrollPosition,
        prevScrollingDirection,
        offset,
      }
    );

    if (newScrollingDirection !== prevScrollingDirection) {
      directionRef.value = newScrollingDirection;
    }

    return newScrollPosition;
  };

  const _getScrollingDirection = ({
    prevScrollPosition,
    prevScrollingDirection,
    offset,
  }) => {
    const newScrollPosition = window.pageYOffset;
    if (Math.abs(newScrollPosition - prevScrollPosition) < offset) {
      return {
        newScrollingDirection: prevScrollingDirection,
        newScrollPosition: prevScrollPosition,
      };
    }

    let newScrollingDirection;

    if (newScrollPosition < prevScrollPosition) {
      newScrollingDirection = "up";
    } else {
      newScrollingDirection = "down";
    }

    return { newScrollingDirection, newScrollPosition };
  };

  return {
    scrollingDirection,
    scrollingDirectionMain,
  };
}
