<template>
  <section class="section-filter">
    <div class="base-filter section-filter__filter-base">
      <InputTabGroupSlider
        v-model="list.type"
        :name="'filter-items-type'"
        :options="optionsOffersApplications"
        class="filter-items-type filter-base__filter-items-type"
      />
      <InputTabGroupSlider
        v-if="user.isAuth"
        v-model="list.filter.allSeen"
        :name="'filter-new-seen'"
        :options="optionsAllSeen"
        class="filter-new-seen filter-base__filter-new-seen"
      />
    </div>

    <div class="chips section-filter__chips">
      <ButtonChipSettings @click="openSettings()" />
      <BaseFilterChip :label="'Wroclaw'" :isDeleted="false" />
      <transition-group name="filter-chips">
        <BaseFilterChip
          v-for="filter in filterSettingsChips"
          :key="filter.slug"
          :label="filter.label"
          @delete-me="deleteFilter(filter)"
          class="filter-chip chips_filter-chip"
        />
      </transition-group>
    </div>

    <ListDefaultFilterSettings
      ref="filterSettingsRef"
      v-if="filterSettingsComponent"
      v-model="list.filter.settings"
      @resetFilters="resetSettings()"
      :filter-component="filterSettingsComponent"
    />
  </section>
</template>

<script>
import { computed, markRaw, reactive, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { getSlugsFromDict } from "@/helpers";
import { useUserStore } from "@/stores/user";

import useApplicationNannyDictionary from "@/composables/useApplicationNannyDictionary";
import useOfferNannyDictionary from "@/composables/useOfferNannyDictionary";
import ApplicationNannyListFilterSettings from "@/components/Application/Nanny/ApplicationNannyListFilterSettings.vue";
import OfferNannyListFilterSettings from "@/components/Offer/Nanny/OfferNannyListFilterSettings.vue";

import InputTabGroupSlider from "../UI/Base/InputTabGroupSlider.vue";
import BaseFilterChip from "../UI/Base/BaseFilterChip.vue";
import ButtonChipSettings from "../UI/ButtonChipSettings.vue";
import ListDefaultFilterSettings from "../List/ListDefaultFilterSettings.vue";

export default {
  components: {
    InputTabGroupSlider,
    BaseFilterChip,
    ButtonChipSettings,
    ListDefaultFilterSettings,
  },

  props: {
    listItemsFilter: Object,
    listItemsClass: String,
    listItemsType: String,
  },

  emits: [
    "update:listItemsFilter",
    "update:listItemsClass",
    "update:listItemsType",
  ],

  setup(props, { emit }) {
    const list = reactive({
      filter: props.listItemsFilter,
      class: props.listItemsClass,
      type: props.listItemsType,
    });

    // Way out binding
    watch(
      () => list.filter,
      (newFilter) => {
        if (newFilter === props.listItemsFilter) {
          return;
        }
        emit("update:listItemsFilter", newFilter);
      },
      { deep: true }
    );
    watch(
      () => list.class,
      (newClass) => {
        emit("update:listItemsClass", newClass);
      }
    );
    watch(
      () => list.type,
      (newType) => {
        emit("update:listItemsType", newType);
      }
    );

    // Way in binding
    watch(
      () => props.listItemsFilter,
      (newFilter) => {
        list.filter = newFilter;
      }
    );
    watch(
      () => props.listItemsClass,
      (newClass) => {
        list.class = newClass;
      }
    );
    watch(
      () => props.listItemsType,
      (newType) => {
        list.type = newType;
      }
    );

    const DICTIONARY = computed(() => {
      if (list.type === "offer") {
        if (list.class === "nanny") {
          const { DICTIONARY: OFFER_NANNY_DICT } = useOfferNannyDictionary();
          return OFFER_NANNY_DICT;
        }
        throw new Error(`No DICTIONARY for ${list.class} offers!`);
      }

      if (list.type === "application") {
        if (list.class === "nanny") {
          const { DICTIONARY: APPLICATION_NANNY_DICT } =
            useApplicationNannyDictionary();
          return APPLICATION_NANNY_DICT;
        }
        throw new Error(`No DICTIONARY for ${list.class} applications!`);
      }
      throw new Error(
        `No DICTIONARY for listClass "${list.class}" and listType "${list.type}"!`
      );
    });

    // Todo: turn this into unified loop!
    // Transform filterSettings to object with labels for filterChips
    const filterFromDict = computed(() => {
      const filters = list.filter.settings;
      const filtersReady = {};

      const DICT = DICTIONARY.value;

      if (!filters) {
        return filtersReady;
      }

      if (filters.aupair) {
        filtersReady.aupair = DICT.aupair[filters.aupair];
      }

      if (filters.employmentType) {
        filtersReady.employmentType =
          DICT.employment.types[filters.employmentType];
      }

      if (filters.employmentRegularity) {
        filtersReady.employmentRegularity =
          DICT.employment.regularities[filters.employmentRegularity];
      }

      if (filters.experiences?.length > 0) {
        filtersReady.experiences = getSlugsFromDict(
          filters.experiences,
          DICT.experience.filter
        );
      }

      if (filters.activities?.length > 0) {
        filtersReady.activities = getSlugsFromDict(
          filters.activities,
          DICT.responsibilities.activities
        );
      }

      if (filters.homeCare?.length > 0) {
        filtersReady.homeCare = getSlugsFromDict(
          filters.homeCare,
          DICT.responsibilities.homeCare
        );
      }

      if (filters.languages?.length > 0) {
        filtersReady.languages = getSlugsFromDict(
          filters.languages,
          DICT.languages
        );
      }

      if (filters.perks?.length > 0) {
        filtersReady.perks = getSlugsFromDict(filters.perks, DICT.perks);
      }

      if (filters.feeHour) {
        filtersReady.feeHour = {
          slug: "fee_hour_max",
          value: filters.feeHour,
          label: `< ${filters.feeHour} zł/godzinę`,
        };
      }

      if (filters.withPhotoOnly) {
        filtersReady.withPhotoOnly = DICT.with_photo_only;
      }

      if (filters.kidsAmountMax) {
        if (filters.kidsAmountMax === 1) {
          filtersReady.kidsAmountMax = {
            slug: "kids_amount_max",
            value: filters.feeHour,
            label: `1 dziecko`,
          };
        }

        if (filters.kidsAmountMax === 2) {
          filtersReady.kidsAmountMax = {
            slug: "kids_amount_max",
            value: filters.feeHour,
            label: `do 2 dzieci`,
          };
        }
      }

      return filtersReady;
    });

    const filterSettingsChips = computed(() => {
      if (Object.keys(filterFromDict.value).length === 0) return null;

      const filterChips = [];

      for (let [key, filter] of Object.entries(filterFromDict.value)) {
        // Continue if no filter
        if (filter == null) continue;

        // If filter is Array, add meta properties to filter children and continue
        if (Array.isArray(filter)) {
          // Adding 'parentKey' and 'indexInArray props for the filterChip possibility to be deleted
          filter.forEach((prop) => {
            prop["parentKey"] = key;
            prop["inArray"] = true;
            filterChips.push(prop);
          });

          continue;
        }

        // Adding 'parentKey' prop for the filterChip possibility to be deleted
        filter["parentKey"] = key;
        filterChips.push(filter);
      }

      return filterChips;
    });

    // Delete filterChip (with filter obviously)
    const deleteFilter = (filter) => {
      // TODO: ???????
      if ("parentKey" in filter === false) return;

      // If filter is a child-filter in array
      if ("inArray" in filter && filter.inArray === true) {
        const index = list.filter.settings[filter.parentKey].indexOf(
          filter.slug
        );

        // Delete child-filter from parent-filter array
        list.filter.settings[filter.parentKey].splice(index, 1);

        return;
      }

      // Delete filter
      list.filter.settings[filter.parentKey] = null;
    };

    // Options for sliders
    // todo: Better to fetch this from dict!
    const optionsAllSeen = [
      { slug: "all", label: "Wszystkie" },
      // { slug: "new", label: "Nowe" },
      { slug: "seen", label: "Przeglądane" },
    ];

    const user = useUserStore();
    const optionsOffersApplications = computed(() => {
      const userType = user.userType;

      if (userType === "client") {
        return getSlugsFromDict(
          ["application", "offer"],
          DICTIONARY.value.listItemsTypes
        );
      }

      if (userType === "contractor") {
        return getSlugsFromDict(
          ["offer", "application"],
          DICTIONARY.value.listItemsTypes
        );
      }

      // Default as client's version
      return getSlugsFromDict(
        ["application", "offer"],
        DICTIONARY.value.listItemsTypes
      );
    });

    // Open modal with filterSettings
    const filterSettingsRef = ref(null);
    const openSettings = () => {
      if (filterSettingsRef.value === null) {
        return;
      }
      filterSettingsRef.value.open();
    };

    const resetSettings = () => {
      if (list.filter.settings) {
        list.filter.settings = {};
      }
    };

    // Choosing a proper component depending on filter type (Application/ Offer AND Nanny/ PetSitter/ Cleaner/ etc...)
    const filterSettingsComponent = computed(() => {
      if (list.type === "offer") {
        if (list.class === "nanny") {
          return markRaw(OfferNannyListFilterSettings);
        }
        return undefined;
      }

      if (list.type === "application") {
        if (list.class === "nanny") {
          return markRaw(ApplicationNannyListFilterSettings);
        }
        return undefined;
      }

      return undefined;
    });

    return {
      filterSettingsRef,

      user,
      optionsOffersApplications,
      optionsAllSeen,

      openSettings,
      resetSettings,

      filterSettingsChips,
      deleteFilter,

      filterSettingsComponent,
      list,
    };
  },
};
</script>

<style scoped>
.section-filter {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 20px;
}
.section-filter__filter-base {
  width: 100%;
  display: flex;
  flex-flow: row;
  column-gap: 40px;
}
.section-filter__chips {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
}
.chips_filter-chip:hover {
  will-change: opacity, max-width;
}

@media (max-width: 1200px) {
  .section-filter__filter-base {
    width: 100%;
    display: flex;
    flex-flow: column;
    row-gap: 20px;
  }
  .section-filter__chips {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
  }
}

/* Animations */
.filter-chips-leave-active {
  will-change: opacity, max-width;
  animation: filter-leave 0.8s;
}
@keyframes filter-leave {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 0.5;
    transform: scale(0.7);
  }
  75% {
    opacity: 0;
    transform: scale(0.8);
    max-width: 100%;
  }
  100% {
    opacity: 0;
    max-width: 0%;
  }
}
</style>
