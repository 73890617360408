<template>
  <!-- CHIPS CHECKBOXES -->
  <label class="checkbox">
    <InputCheckbox
      class="dot checkbox__dot"
      :name="name"
      @update:model-value="$emit('update:modelValue', $event)"
      :model-value="modelValue"
      :value="value"
      :checked="checked"
    />
    <span class="chip checkbox__chip">
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Boolean],
    },
    value: {
      type: [String, Boolean],
      default: "",
    },
    label: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    checked: {
      type: Boolean,
      default: null,
    },
  },

  emits: ["update:modelValue"],
};
</script>

<style scoped>
.checkbox {
  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  line-height: 10px;
  border-radius: var(--button-border-radius);
}

.checkbox__chip {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 var(--checkbox-chip-padding);
  min-width: fit-content;
  width: fit-content;
  height: var(--checkbox-chip-height);

  color: var(--checkbox-chip-font-color);
  font-family: var(--default-font-family);
  font-size: var(--checkbox-chip-font-size);
  font-weight: var(--checkbox-chip-font-weight);
  text-align: center;

  background-color: var(--button-tertiary-bg-color);
  border: var(--button-tertiary-border);
  border-radius: var(--button-border-radius);
  box-sizing: border-box;
}

.checkbox__chip:hover {
  border: var(--checkbox-chip-hover-border);
  padding: 0 calc(var(--checkbox-chip-padding) - 1px);
  cursor: pointer;
}

.checkbox__dot {
  display: none;
}

.checkbox__dot:checked + .checkbox__chip {
  border: var(--checkbox-chip-active-border);
  background-color: var(--checkbox-chip-active-bg-color);
  padding: 0 calc(var(--checkbox-chip-padding) - 1px);
}

@media (max-width: 1200px) {
}
</style>
