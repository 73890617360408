<template>
  <div v-if="filter" class="filters-application-nanny">
    <div class="section filters-application-nanny__section">
      <BaseH3 v-html="'Miasto'" />
      <BaseGroupChipItem :label="'Wroclaw'" />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openAupair()"
          :label="'Zamieszkanie'"
          :custom-disabled="isOpened.aupair"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross v-if="isOpened.aupair" @click="closeAupair()" />
      </div>
      <InputTabGroupSlider
        v-if="isOpened.aupair"
        v-model="filter.aupair"
        :name="'filter-aupair'"
        :options="optionsAupair"
      />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openEmploymentType()"
          :label="'Etat'"
          :custom-disabled="isOpened.employmentType"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross
          v-if="isOpened.employmentType"
          @click="closeEmploymentType()"
        />
      </div>
      <InputTabGroupSlider
        v-if="isOpened.employmentType"
        v-model="filter.employmentType"
        :name="'filter-employment-type'"
        :options="optionsEmploymentType"
      />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openKidsAmountMax()"
          :label="'Maks. liczba dzieci'"
          :custom-disabled="isOpened.kidsAmountMax"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross
          v-if="isOpened.kidsAmountMax"
          @click="closeKidsAmountMax()"
        />
      </div>
      <InputTabGroupSlider
        v-if="isOpened.kidsAmountMax"
        v-model="filter.kidsAmountMax"
        :name="'filter-kids-amount-max'"
        :options="optionsKidsAmountMax"
      />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openLanguages()"
          :label="'Język używany w rodzinie'"
          :custom-disabled="isOpened.languages"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross
          v-if="isOpened.languages"
          @click="closeLanguages()"
        />
      </div>
      <InputCheckboxGroupChips
        v-if="isOpened.languages"
        v-model="filter.languages"
        :name="'filter-home-care'"
        :options="optionsLanguages"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import useOfferNannyDictionary from "@/composables/useOfferNannyDictionary";

import BaseGroupChipItem from "../../UI/Base/BaseGroupChipItem.vue";
import InputTabGroupSlider from "../../UI/Base/InputTabGroupSlider.vue";
import ButtonLink from "../../UI/Base/ButtonLink.vue";
import InputCheckboxGroupChips from "../../UI/Base/InputCheckboxGroupChips.vue";
import BaseButtonIconCross from "../../UI/Base/BaseButtonIconCross.vue";
import BaseH3 from "../../UI/Base/BaseH3.vue";

export default {
  components: {
    InputTabGroupSlider,
    BaseGroupChipItem,
    ButtonLink,
    InputCheckboxGroupChips,
    BaseButtonIconCross,
    BaseH3,
  },

  props: {
    modelValue: Object,
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const { DICTIONARY } = useOfferNannyDictionary();

    // Todo: sync props with DICT (manually)!
    const filter = ref(props.modelValue);

    // Update modelValue on filter change
    watch(filter, (newFilter) => {
      emit("update:modelValue", newFilter);
    });

    // Update filter on modelValue change
    watch(
      () => props.modelValue,
      (newFilter) => {
        if (Object.keys(newFilter).length === 0) {
          // if filter is impty => close all filters
          closeAllFilters();
        }
        filter.value = newFilter;
      }
    );

    const isOpened = reactive({
      aupair: filter.value.aupair != null,
      employmentType: filter.value.employmentType != null,
      kidsAmountMax: filter.value.kidsAmountMax != null,
      languages: filter.value.languages?.length > 0,
    });

    const AUPAIR_DEFAULT = "not_in_aupair";
    const optionsAupair = computed(() => {
      return Object.values(DICTIONARY.aupair);
    });

    const EMPLOYMENT_TYPE_DEFAULT = "full_time";
    const optionsEmploymentType = computed(() => {
      const options = [];
      Object.values(DICTIONARY.employment.types).forEach((type) => {
        options.push({
          slug: type.slug,
          label: type.labelShort,
        });
      });

      return options;
    });

    const KIDS_AMOUNT_MAX_DEFAULT = 2;
    const optionsKidsAmountMax = [
      {
        slug: "1_kid",
        label: "1 dziecko",
        value: 1,
      },
      {
        slug: "2_kids",
        label: "2 dzieci",
        value: 2,
      },
    ];

    const optionsLanguages = computed(() => {
      return Object.values(DICTIONARY.languages);
    });

    // Close filters
    const closeAupair = () => {
      isOpened.aupair = false;
      filter.value.aupair = null;
    };
    const closeEmploymentType = () => {
      isOpened.employmentType = false;
      filter.value.employmentType = null;
    };
    const closeKidsAmountMax = () => {
      isOpened.kidsAmountMax = false;
      filter.value.kidsAmountMax = null;
    };
    const closeLanguages = () => {
      isOpened.languages = false;
      if (filter.value.languages?.length > 0) {
        filter.value.languages = [];
      }
    };
    const closeAllFilters = () => {
      closeAupair();
      closeEmploymentType();
      closeKidsAmountMax();
      closeLanguages();
    };

    // Open filters
    const openAupair = () => {
      isOpened.aupair = true;
      filter.value.aupair = AUPAIR_DEFAULT;
    };
    const openEmploymentType = () => {
      isOpened.employmentType = true;
      filter.value.employmentType = EMPLOYMENT_TYPE_DEFAULT;
    };
    const openKidsAmountMax = () => {
      isOpened.kidsAmountMax = true;
      filter.value.kidsAmountMax = KIDS_AMOUNT_MAX_DEFAULT;
    };
    const openLanguages = () => {
      isOpened.languages = true;
    };

    return {
      filter,
      isOpened,

      closeAupair,
      closeEmploymentType,
      closeKidsAmountMax,
      closeLanguages,

      openAupair,
      openEmploymentType,
      openKidsAmountMax,
      openLanguages,

      optionsAupair,
      optionsEmploymentType,
      optionsKidsAmountMax,
      optionsLanguages,
    };
  },
};
</script>

<style scoped>
.filters-application-nanny {
  width: 560px;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}
.filters-application-nanny__section {
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 10px;
}
.section__title {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 20px;
}
.title__button-link[custom-disabled="true"],
.title__button-link[custom-disabled="true"] a,
.title__button-link[custom-disabled="true"] button {
  pointer-events: none !important;
  cursor: default !important;
}
.section__filter-fee-hour {
  margin-left: 45px;
}
.section__filter {
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}

@media (max-width: 1200px) {
  .filters-application-nanny {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
  }
}
</style>
