<template>
  <div class="filters filters-initial" :class="filtersClassList">
    <ListDefaultFilter v-bind="$attrs" />
  </div>
</template>

<script>
import { reactive, ref } from "vue";

import ListDefaultFilter from "@/components/List/ListDefaultFilter.vue";

export default {
  components: { ListDefaultFilter },

  expose: ["stick", "unstick", "stickAnimated", "unstickAnimated"],

  setup() {
    const isSticky = ref(false);
    const animateToEnter = ref(false);
    const animateToLeave = ref(false);

    const filtersClassList = reactive({
      "filters-sticky": isSticky,
      "animate-to-enter": animateToEnter,
      "animate-to-leave": animateToLeave,
    });

    const stick = () => {
      animateToEnter.value = false;
      animateToLeave.value = false;
      isSticky.value = true;
    };

    const unstick = () => {
      animateToEnter.value = false;
      animateToLeave.value = false;
      isSticky.value = false;
    };

    const stickAnimated = () => {
      animateToLeave.value = false;
      animateToEnter.value = true;
      isSticky.value = true;
    };

    const unstickAnimated = () => {
      animateToEnter.value = false;
      animateToLeave.value = true;
      isSticky.value = false;
    };

    return {
      filtersClassList,
      isSticky,
      stick,
      unstick,
      stickAnimated,
      unstickAnimated,
    };
  },
};
</script>

<style scoped>
.filters.filters-initial {
  width: var(--layout-default--width);
  display: flex;
  align-items: center;
  will-change: transform;
  background-color: var(--main-bg-color);
  padding-bottom: 20px;
  margin-bottom: -20px;
}
.filters.filters-sticky {
  position: sticky;
  top: var(--header-default--height);
  z-index: 99999999;
}

@keyframes to-enter {
  from {
    position: sticky;
    top: var(--header-default--height);
    z-index: 99999999;
    transform: translateY(calc(-100% - var(--header-default--height)));
  }
  to {
    position: sticky;
    top: var(--header-default--height);
    z-index: 99999999;
    transform: translateY(0px);
  }
}
@keyframes to-leave {
  from {
    position: sticky;
    top: var(--header-default--height);
    z-index: 99999999;
    transform: translateY(0px);
  }
  to {
    position: sticky;
    top: var(--header-default--height);
    z-index: 99999999;
    transform: translateY(calc(-100% - var(--header-default--height)));
  }
}

.animate-to-enter {
  animation: to-enter 400ms ease-out;
}

.animate-to-leave {
  animation: to-leave 200ms ease-in;
}

@media (max-width: 1200px) {
  .filters.filters-initial {
    width: var(--layout-default--width);
    display: flex;
    align-items: center;
    will-change: transform;
    background-color: var(--main-bg-color);
    padding: 20px 0px;
    margin: -20px 0px;
  }
  .filters.filters-sticky {
    position: sticky;
    top: 0px;
    z-index: 99999999;
  }

  @keyframes to-enter {
    from {
      position: sticky;
      top: 0px;
      z-index: 99999999;
      transform: translateY(-100%);
    }
    to {
      position: sticky;
      top: 0px;
      z-index: 99999999;
      transform: translateY(0px);
    }
  }
  @keyframes to-leave {
    from {
      position: sticky;
      top: 0px;
      z-index: 99999999;
      transform: translateY(0px);
    }
    to {
      position: sticky;
      top: 0px;
      z-index: 99999999;
      transform: translateY(-100%);
    }
  }
}
</style>
