<template>
  <section class="section-list">
    <ListDefaultFilterSticky
      ref="filtersRef"
      :list-items-filter="filter"
      @update:list-items-filter="$emit('update:filter', $event)"
      :list-items-class="itemsClass"
      @update:list-items-class="$emit('update:itemsClass', $event)"
      :list-items-type="itemsType"
      @update:list-items-type="$emit('update:itemsType', $event)"
    />

    <div class="above-list-section">
      <div class="items-amount">{{ itemsTotalAmount }} ogłoszenia</div>
      <div class="items-sort">Według aktualności</div>
    </div>

    <ListDefaultItems
      v-intersection="intersectionOptions"
      @intersection="handleIntersection"
      :list-items="items"
      :list-items-class="itemsClass"
      :list-items-type="itemsType"
      @fetch-more="$emit('fetchMore')"
    />

    <div v-if="isFetching">Loading more...</div>
  </section>
</template>

<script>
import { ref, watch } from "vue";

import useScrollingDirection from "@/composables/useScrollingDirection.js";
import intersectonDirective from "@/directives/intersection";

import ListDefaultItems from "@/components/List/ListDefaultItems.vue";
import ListDefaultFilterSticky from "@/components/List/ListDefaultFilterSticky.vue";

export default {
  directives: {
    intersection: intersectonDirective,
  },

  components: { ListDefaultItems, ListDefaultFilterSticky },

  props: {
    items: Array,
    itemsTotalAmount: Number,
    filter: Object,
    itemsClass: String,
    itemsType: String,
    isFetching: Boolean,
  },

  emits: [
    "update:filter",
    "update:itemsClass",
    "update:itemsType",
    "fetchMore",
  ],

  setup(props) {
    const filtersRef = ref(null);
    const isListOnScreen = ref(false);
    const { scrollingDirection, scrollingDirectionMain } =
      useScrollingDirection();

    const handleIntersection = (event) => {
      isListOnScreen.value = event.detail.isOnScreen;
    };

    const intersectionOptions = {
      threshold: 0,
      root: null,
      rootMargin: `0px 0px -100% 0px`,
    };

    watch(
      () => props.isFetching,
      (isFetching) => {
        if (isFetching) {
          filtersRef.value?.unstick();
        }
      }
    );

    watch(scrollingDirection, (direction) => {
      if (direction === "down" && !isListOnScreen.value) {
        return filtersRef.value?.unstick();
      }

      if (direction === "down" && isListOnScreen.value) {
        return filtersRef.value?.unstickAnimated();
      }

      if (
        direction === "up" &&
        scrollingDirectionMain.value === "up" &&
        isListOnScreen.value
      ) {
        return filtersRef.value?.stickAnimated();
      }
    });

    watch(scrollingDirectionMain, (direction) => {
      if (direction === "up" && isListOnScreen.value) {
        return filtersRef.value?.stickAnimated();
      }
    });

    return {
      filtersRef,
      intersectionOptions,
      handleIntersection,

      scrollingDirection,
      scrollingDirectionMain,
    };
  },
};
</script>

<style scoped>
.section-list {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
.above-list-section {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.above-list-section .items-amount,
.above-list-section .items-sort {
  font: var(--system-font);
  opacity: 0.8;
}
@media (max-width: 1200px) {
  .section-list {
    width: 100%;
    padding: 20px 0px 80px;
    display: flex;
    flex-flow: column;
    row-gap: 20px;
  }
}
</style>
