<template>
  <div class="filters-application-nanny">
    <div class="section filters-application-nanny__section">
      <BaseH3 v-html="'Miasto'" />
      <BaseGroupChipItem :label="'Wroclaw'" />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openAupair()"
          :label="'Zamieszkanie'"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
          :custom-disabled="isOpened.aupair"
        />
        <BaseButtonIconCross v-if="isOpened.aupair" @click="closeAupair()" />
      </div>
      <InputTabGroupSlider
        v-if="isOpened.aupair"
        v-model="filter.aupair"
        :name="'filter-aupair'"
        :options="optionsAupair"
      />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openEmploymentType()"
          :label="'Etat'"
          :custom-disabled="
            isOpened.employmentType || isOpened.employmentRegularity
          "
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross
          v-if="isOpened.employmentType || isOpened.employmentRegularity"
          @click="closeEmployment()"
        />
      </div>
      <div
        v-if="isOpened.employmentType || isOpened.employmentRegularity"
        class="filter section__filter"
      >
        <InputTabGroupSlider
          v-if="isOpened.employmentType"
          v-model="filter.employmentType"
          :name="'filter-employment-type'"
          :options="optionsEmploymentType"
        />
        <InputTabGroupSlider
          v-if="isOpened.employmentRegularity"
          v-model="filter.employmentRegularity"
          :name="'filter-employment-regularity'"
          :options="optionsEmploymentRegularity"
        />
      </div>
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openExperiences()"
          :label="'Doświadczenie...'"
          :custom-disabled="isOpened.experiences"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross
          v-if="isOpened.experiences"
          @click="closeExperiences()"
        />
      </div>
      <InputCheckboxGroupChips
        v-if="isOpened.experiences"
        v-model="filter.experiences"
        :name="'filter-experience'"
        :options="optionsExperiences"
      />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openActivities()"
          :label="'Zajęcia'"
          :custom-disabled="isOpened.activities"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross
          v-if="isOpened.activities"
          @click="closeActivities()"
        />
      </div>
      <InputCheckboxGroupChips
        v-if="isOpened.activities"
        v-model="filter.activities"
        :name="'filter-activities'"
        :options="optionsActivities"
      />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openHomeCare()"
          :label="'Pomoc domowa'"
          :custom-disabled="isOpened.homeCare"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross
          v-if="isOpened.homeCare"
          @click="closeHomeCare()"
        />
      </div>
      <InputCheckboxGroupRound
        v-if="isOpened.homeCare"
        v-model="filter.homeCare"
        :name="'filter-home-care'"
        :options="optionsHomeCare"
      />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openLanguages()"
          :label="'Język używany w rodzinie'"
          :custom-disabled="isOpened.languages"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross
          v-if="isOpened.languages"
          @click="closeLanguages()"
        />
      </div>
      <InputCheckboxGroupChips
        v-if="isOpened.languages"
        v-model="filter.languages"
        :name="'filter-home-care'"
        :options="optionsLanguages"
      />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openPerks()"
          :label="'Wymagania'"
          :custom-disabled="isOpened.perks"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross v-if="isOpened.perks" @click="closePerks()" />
      </div>
      <InputCheckboxGroupChips
        v-if="isOpened.perks"
        v-model="filter.perks"
        :name="'filter-perks'"
        :options="optionsPerks"
      />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openFeeHour()"
          :label="'Wynagrodzenie'"
          :custom-disabled="isOpened.feeHour"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross v-if="isOpened.feeHour" @click="closeFeeHour()" />
      </div>
      <InputTextFeeHour
        v-if="isOpened.feeHour"
        v-model="filter.feeHour"
        :name="'filter-fee-hour'"
        :prefix="'Do'"
        class="filter-fee-hour section__filter-fee-hour"
      />
    </div>

    <div class="section filters-application-nanny__section">
      <div class="title section__title">
        <ButtonLink
          @click="openWithPhotoOnly()"
          :label="'Tylko ze zdjęciem '"
          :custom-disabled="isOpened.withPhotoOnly"
          :style="'font: var(--h3--font)'"
          class="button-link title__button-link"
        />
        <BaseButtonIconCross
          v-if="isOpened.withPhotoOnly"
          @click="closeWithPhotoOnly()"
        />
      </div>
      <BaseInputCheckboxItemChip
        v-if="isOpened.withPhotoOnly"
        v-model="filter.withPhotoOnly"
        :name="'filter-with-photo'"
        :label="optionWithPhotoOnly.label"
        :value="optionWithPhotoOnly.slug"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import useApplicationNannyDictionary from "@/composables/useApplicationNannyDictionary";

import BaseGroupChipItem from "../../UI/Base/BaseGroupChipItem.vue";
import InputTabGroupSlider from "../../UI/Base/InputTabGroupSlider.vue";
import ButtonLink from "../../UI/Base/ButtonLink.vue";
import InputCheckboxGroupChips from "../../UI/Base/InputCheckboxGroupChips.vue";
import InputCheckboxGroupRound from "../../UI/Base/InputCheckboxGroupRound.vue";
import BaseButtonIconCross from "../../UI/Base/BaseButtonIconCross.vue";
import InputTextFeeHour from "../../UI/InputTextFeeHour.vue";
import BaseInputCheckboxItemChip from "../../UI/Base/BaseInputCheckboxItemChip.vue";
import BaseH3 from "../../UI/Base/BaseH3.vue";

export default {
  components: {
    InputTabGroupSlider,
    BaseGroupChipItem,
    ButtonLink,
    InputCheckboxGroupChips,
    InputCheckboxGroupRound,
    BaseButtonIconCross,
    InputTextFeeHour,
    BaseInputCheckboxItemChip,
    BaseH3,
  },

  props: {
    modelValue: Object,
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const { DICTIONARY } = useApplicationNannyDictionary();

    // Todo: sync props with DICT (manually)!
    const filter = ref(props.modelValue);

    // Update modelValue on filter change
    watch(filter, (newFilter) => {
      emit("update:modelValue", newFilter);
    });

    // Update filter on modelValue change
    watch(
      () => props.modelValue,
      (newFilter) => {
        if (Object.keys(newFilter).length === 0) {
          closeAllFilters();
        }
        filter.value = newFilter;
      }
    );

    const isOpened = reactive({
      aupair: filter.value.aupair != null,
      employmentType: filter.value.employmentType != null,
      employmentRegularity: filter.value.employmentRegularity != null,
      experiences: filter.value.experiences?.length > 0,
      activities: filter.value.activities?.length > 0,
      homeCare: filter.value.homeCare?.length > 0,
      languages: filter.value.languages?.length > 0,
      perks: filter.value.perks?.length > 0,
      feeHour: filter.value.feeHour != null,
      withPhotoOnly: filter.value.withPhotoOnly != null,
    });

    const AUPAIR_DEFAULT = "not_in_aupair";
    const optionsAupair = computed(() => {
      return Object.values(DICTIONARY.aupair);
    });

    const EMPLOYMENT_TYPE_DEFAULT = "full_time";
    const optionsEmploymentType = computed(() => {
      const options = [];
      Object.values(DICTIONARY.employment.types).forEach((type) => {
        options.push({
          slug: type.slug,
          label: type.labelShort,
        });
      });

      return options;
    });

    const EMPLOYMENT_REGULARITY_DEFAULT = "regularly";
    watch(
      () => filter.value.employmentType,
      (newEmploymentType) => {
        if (newEmploymentType === "part_time") {
          openEmploymentRegularity();
        } else {
          filter.value.employmentRegularity = null;
        }
      }
    );
    const optionsEmploymentRegularity = computed(() => {
      return Object.values(DICTIONARY.employment.regularities);
    });

    const optionsExperiences = computed(() => {
      const options = [];
      Object.values(DICTIONARY.experience.filter).forEach((experience) => {
        options.push({
          slug: experience.slug,
          label: experience.labelShort,
        });
      });

      return options;
    });

    const optionsActivities = computed(() => {
      const options = [];
      Object.values(DICTIONARY.responsibilities.activities).forEach(
        (activity) => {
          options.push({
            slug: activity.slug,
            label: activity.labelShort,
          });
        }
      );

      return options;
    });

    const optionsHomeCare = computed(() => {
      return Object.values(DICTIONARY.responsibilities.homeCare);
    });

    const optionsLanguages = computed(() => {
      return Object.values(DICTIONARY.languages);
    });

    const optionsPerks = computed(() => {
      return Object.values(DICTIONARY.perks);
    });

    const FEE_HOUR_DEFAULT = "";

    const WITH_PHOTO_ONLY_DEFAULT = true;
    const optionWithPhotoOnly = computed(() => {
      return DICTIONARY.with_photo_only;
    });
    watch(
      () => filter.value.withPhotoOnly,
      (newValue) => {
        if (newValue === false) {
          filter.value["withPhotoOnly"] = null;
        }
      }
    );

    // Close filters
    const closeAupair = () => {
      isOpened.aupair = false;
      filter.value.aupair = null;
    };
    const closeEmployment = () => {
      isOpened.employmentType = false;
      isOpened.employmentRegularity = false;

      filter.value.employmentType = null;
      filter.value.employmentRegularity = null;
    };
    const closeExperiences = () => {
      isOpened.experiences = false;
      if (filter.value.experiences?.length > 0) {
        filter.value.experiences = [];
      }
    };
    const closeActivities = () => {
      isOpened.activities = false;
      if (filter.value.activities?.length > 0) {
        filter.value.activities = [];
      }
    };
    const closeHomeCare = () => {
      isOpened.homeCare = false;
      if (filter.value.homeCare?.length > 0) {
        filter.value.homeCare = [];
      }
    };
    const closeLanguages = () => {
      isOpened.languages = false;
      if (filter.value.languages?.length > 0) {
        filter.value.languages = [];
      }
    };
    const closePerks = () => {
      isOpened.perks = false;
      if (filter.value.perks?.length > 0) {
        filter.value.perks = [];
      }
    };
    const closeFeeHour = () => {
      isOpened.feeHour = false;
      filter.value.feeHour = null;
    };
    const closeWithPhotoOnly = () => {
      isOpened.withPhotoOnly = false;
      filter.value.withPhotoOnly = null;
    };
    const closeAllFilters = () => {
      closeAupair();
      closeEmployment();
      closeExperiences();
      closeActivities();
      closeHomeCare();
      closeLanguages();
      closePerks();
      closeFeeHour();
      closeWithPhotoOnly();
    };

    // Open filters
    const openAupair = () => {
      isOpened.aupair = true;
      filter.value.aupair = AUPAIR_DEFAULT;
    };
    const openEmploymentType = () => {
      isOpened.employmentType = true;
      filter.value.employmentType = EMPLOYMENT_TYPE_DEFAULT;
    };
    const openEmploymentRegularity = () => {
      isOpened.employmentRegularity = true;
      filter.value.employmentRegularity = EMPLOYMENT_REGULARITY_DEFAULT;
    };
    const openExperiences = () => {
      isOpened.experiences = true;
    };
    const openActivities = () => {
      isOpened.activities = true;
    };
    const openHomeCare = () => {
      isOpened.homeCare = true;
    };
    const openLanguages = () => {
      isOpened.languages = true;
    };
    const openPerks = () => {
      isOpened.perks = true;
    };
    const openFeeHour = () => {
      isOpened.feeHour = true;
      filter.value.feeHour = FEE_HOUR_DEFAULT;
    };
    const openWithPhotoOnly = () => {
      isOpened.withPhotoOnly = true;
      filter.value.withPhotoOnly = WITH_PHOTO_ONLY_DEFAULT;
    };

    return {
      filter,
      isOpened,

      closeAupair,
      closeEmployment,
      closeExperiences,
      closeActivities,
      closeHomeCare,
      closeLanguages,
      closePerks,
      closeFeeHour,
      closeWithPhotoOnly,

      openAupair,
      openEmploymentType,
      openEmploymentRegularity,
      openExperiences,
      openActivities,
      openHomeCare,
      openLanguages,
      openPerks,
      openFeeHour,
      openWithPhotoOnly,

      optionsAupair,
      optionsEmploymentType,
      optionsEmploymentRegularity,
      optionsExperiences,
      optionsActivities,
      optionsHomeCare,
      optionsLanguages,
      optionsPerks,
      optionWithPhotoOnly,
    };
  },
};
</script>

<style scoped>
.filters-application-nanny {
  width: 560px;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}
.filters-application-nanny__section {
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 10px;
}
.section__title {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 20px;
}
.title__button-link[custom-disabled="true"],
.title__button-link[custom-disabled="true"] a,
.title__button-link[custom-disabled="true"] button {
  pointer-events: none !important;
  cursor: default !important;
}
.section__filter-fee-hour {
  margin-left: 45px;
}
.section__filter {
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}

@media (max-width: 1200px) {
  .filters-application-nanny {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
  }
}
</style>
