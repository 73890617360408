<template>
  <ContainerModal ref="modalRef">
    <div class="container-modal-filter">
      <LayoutViewWrapper
        class="filter-settings container-modal-filter__filter-settings"
      >
        <BaseButtonIconCross
          class="filter-settings__close-button"
          @click="close"
        />
        <BaseH1 :text="'Filtry'" :short="true" class="filter-settings__title" />
        <div class="filter-settings__scrollable-content">
          <component
            :is="filterComponent"
            @update:model-value="$emit('update:modelValue', $event)"
            :model-value="modelValue"
          />
        </div>
        <div class="filter-settings__action-buttons">
          <ButtonPrimary
            @click="close"
            :label="'Pokaż wyniki'"
            :size="'x-small'"
          />
          <ButtonLink
            @click="$emit('resetFilters')"
            :label="'Resetuj'"
            :size="'x-small'"
            class="button-right nav-mobile-filter__button-right"
          />
        </div>
      </LayoutViewWrapper>

      <ListDefaultFilterSettingsNavMobile
        v-if="isMobile"
        @showResults="close"
        @resetFilters="$emit('resetFilters')"
      />
    </div>
  </ContainerModal>
</template>

<script>
import { ref } from "vue";

import ContainerModal from "@/layouts/ContainerModal.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import LayoutViewWrapper from "@/layouts/LayoutViewWrapper.vue";
import ListDefaultFilterSettingsNavMobile from "@/components/List/ListDefaultFilterSettingsNavMobile.vue";
import useIsMobile from "@/composables/useIsMobile";
import BaseButtonIconCross from "../UI/Base/BaseButtonIconCross.vue";
import ButtonPrimary from "../UI/Base/ButtonPrimary.vue";

export default {
  components: {
    ContainerModal,
    BaseH1,
    ButtonLink,
    LayoutViewWrapper,
    ListDefaultFilterSettingsNavMobile,
    BaseButtonIconCross,
    ButtonPrimary,
  },

  props: {
    modelValue: Object,
    filterComponent: {
      type: Object,
      required: true,
    },
  },

  emits: ["resetFilters", "update:modelValue"],

  expose: ["open", "close"],

  setup() {
    const modalRef = ref(null);
    const { isMobile } = useIsMobile();

    const open = () => {
      if (modalRef.value === null) return;
      modalRef.value.open();
    };

    const close = () => {
      if (modalRef.value === null) return;
      modalRef.value.close();
    };

    return {
      isMobile,
      modalRef,
      open,
      close,
    };
  },
};
</script>

<style scoped>
.container-modal-filter__filter-settings {
  max-height: 80vh;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.filter-settings__title {
  margin-bottom: 30px;
}
.filter-settings__close-button {
  position: absolute;
  top: 17px;
  right: 17px;
}
.filter-settings__scrollable-content {
  overflow-y: scroll;
}
.filter-settings__action-buttons {
  margin-top: 10px;
  height: 90px;
  display: flex;
  align-items: center;
  column-gap: 40px;
}

@media (max-width: 1200px) {
  .filter-settings__close-button {
    display: none;
  }
  .filter-settings__action-buttons {
    display: none;
  }
  .filter-settings__title {
    margin-bottom: 0px;
  }
  .container-modal-filter {
    width: 100vw;
    min-height: 100vh;
    height: fit-content;
    background-color: var(--main-bg-color);
  }
  .container-modal-filter__filter-settings {
    margin: 30px auto 70px;
    width: var(--layout-default--width);
    max-height: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 20px;
  }
  .filter-settings__scrollable-content {
    width: 100%;
  }
}
</style>
