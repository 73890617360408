<template>
  <div class="nav-mobile-filter-wrapper">
    <nav class="nav-mobile-filter">
      <BaseButtonIconArrowBack
        @click="
          $emit('showResults');
          $emit('resetFilters');
        "
        :size="'small'"
        class="button-left nav-mobile-filter__button-left"
      />
      <ButtonPrimary
        @click="$emit('showResults')"
        :label="'Pokaż wyniki'"
        :size="'x-small'"
      />
      <ButtonLink
        @click="$emit('resetFilters')"
        :label="'Resetuj'"
        :size="'x-small'"
        class="button-right nav-mobile-filter__button-right"
      />
    </nav>
  </div>
</template>

<script>
import BaseButtonIconArrowBack from "@/components/UI/Base/BaseButtonIconArrowBack.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import ButtonPrimary from "../UI/Base/ButtonPrimary.vue";

export default {
  components: { BaseButtonIconArrowBack, ButtonLink, ButtonPrimary },

  emits: ["showResults", "resetFilters"],
};
</script>

<style scoped>
.nav-mobile-filter-wrapper {
  width: 100vw;

  position: fixed;
  bottom: 0;
  left: 0;

  background-color: var(--main-bg-color);
  border-top: 1px solid rgba(22, 32, 94, 0.06);
}
.nav-mobile-filter {
  width: var(--layout-default--width);
  height: 50px;

  margin: 0 auto;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.nav-mobile-filter__button-left,
.nav-mobile-filter__button-right {
  width: calc((100% - 3 * 10px) / 4);
  opacity: 0.4;
}
.nav-mobile-filter__button-left:active,
.nav-mobile-filter__button-right:active {
  opacity: 1;
}
</style>
